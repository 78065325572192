import { freeze } from './utils';

export const html = freeze([
 'a',
 'abbr',
 'acronym',
 'address',
 'area',
 'article',
 'aside',
 'audio',
 'b',
 'bdi',
 'bdo',
 'big',
 'blink',
 'blockquote',
 'body',
 'br',
 'button',
 'canvas',
 'caption',
 'center',
 'cite',
 'code',
 'col',
 'colgroup',
 'content',
 'data',
 'datalist',
 'dd',
 'decorator',
 'del',
 'details',
 'dfn',
 'dir',
 'div',
 'dl',
 'dt',
 'element',
 'em',
 'fieldset',
 'figcaption',
 'figure',
 'font',
 'footer',
 'form',
 'h1',
 'h2',
 'h3',
 'h4',
 'h5',
 'h6',
 'head',
 'header',
 'hgroup',
 'hr',
 'html',
 'i',
 'img',
 'input',
 'ins',
 'kbd',
 'label',
 'legend',
 'li',
 'main',
 'map',
 'mark',
 'marquee',
 'menu',
 'menuitem',
 'meter',
 'nav',
 'nobr',
 'ol',
 'optgroup',
 'option',
 'output',
 'p',
 'picture',
 'pre',
 'progress',
 'q',
 'rp',
 'rt',
 'ruby',
 's',
 'samp',
 'section',
 'select',
 'shadow',
 'small',
 'source',
 'spacer',
 'span',
 'strike',
 'strong',
 'style',
 'sub',
 'summary',
 'sup',
 'table',
 'tbody',
 'td',
 'template',
 'textarea',
 'tfoot',
 'th',
 'thead',
 'time',
 'tr',
 'track',
 'tt',
 'u',
 'ul',
 'var',
 'video',
 'wbr',
]);

// SVG
export const svg = freeze([
 'svg',
 'a',
 'altglyph',
 'altglyphdef',
 'altglyphitem',
 'animatecolor',
 'animatemotion',
 'animatetransform',
 'audio',
 'canvas',
 'circle',
 'clippath',
 'defs',
 'desc',
 'ellipse',
 'filter',
 'font',
 'g',
 'glyph',
 'glyphref',
 'hkern',
 'image',
 'line',
 'lineargradient',
 'marker',
 'mask',
 'metadata',
 'mpath',
 'path',
 'pattern',
 'polygon',
 'polyline',
 'radialgradient',
 'rect',
 'stop',
 'style',
 'switch',
 'symbol',
 'text',
 'textpath',
 'title',
 'tref',
 'tspan',
 'video',
 'view',
 'vkern',
]);

export const svgFilters = freeze([
 'feBlend',
 'feColorMatrix',
 'feComponentTransfer',
 'feComposite',
 'feConvolveMatrix',
 'feDiffuseLighting',
 'feDisplacementMap',
 'feDistantLight',
 'feFlood',
 'feFuncA',
 'feFuncB',
 'feFuncG',
 'feFuncR',
 'feGaussianBlur',
 'feMerge',
 'feMergeNode',
 'feMorphology',
 'feOffset',
 'fePointLight',
 'feSpecularLighting',
 'feSpotLight',
 'feTile',
 'feTurbulence',
]);

export const mathMl = freeze([
 'math',
 'menclose',
 'merror',
 'mfenced',
 'mfrac',
 'mglyph',
 'mi',
 'mlabeledtr',
 'mmultiscripts',
 'mn',
 'mo',
 'mover',
 'mpadded',
 'mphantom',
 'mroot',
 'mrow',
 'ms',
 'mspace',
 'msqrt',
 'mstyle',
 'msub',
 'msup',
 'msubsup',
 'mtable',
 'mtd',
 'mtext',
 'mtr',
 'munder',
 'munderover',
]);

export const text = freeze(['#text']);
