import * as TAGS from './tags';
import * as ATTRS from './attrs';
import * as EXPRESSIONS from './regexp';
import {
 addToSet,
 clone,
 freeze,
 objectKeys,
 arrayForEach,
 arrayIndexOf,
 arrayJoin,
 arrayPop,
 arrayPush,
 arraySlice,
 stringMatch,
 stringReplace,
 stringToLowerCase,
 stringIndexOf,
 stringTrim,
 regExpTest,
 regExpCreate,
 typeErrorCreate,
} from './utils';

const getGlobal = () => (typeof window === 'undefined' ? null : window);

/**
 * Creates a no-op policy for internal use only.
 * Don't export this function outside this module!
 * @param {?TrustedTypePolicyFactory} trustedTypes The policy factory.
 * @param {Document} document The document object (to determine policy name suffix)
 * @return {?TrustedTypePolicy} The policy created (or null, if Trusted Types
 * are not supported).
 */
const _createTrustedTypesPolicy = function(trustedTypes, document) {
 if (
 typeof trustedTypes !== 'object' ||
 typeof trustedTypes.createPolicy !== 'function'
 ) {
 return null;
 }

 // Allow the callers to control the unique policy name
 // by adding a data-tt-policy-suffix to the script element with the DOMPurify.
 // Policy creation with duplicate names throws in Trusted Types.
 let suffix = null;
 const ATTR_NAME = 'data-tt-policy-suffix';
 if (
 document.currentScript &&
 document.currentScript.hasAttribute(ATTR_NAME)
 ) {
 suffix = document.currentScript.getAttribute(ATTR_NAME);
 }

 const policyName = 'dompurify' + (suffix ? '#' + suffix : '');

 try {
 return trustedTypes.createPolicy(policyName, {
 createHTML(html) {
 return html;
 },
 });
 } catch (error) {
 // Policy creation failed (most likely another DOMPurify script has
 // already run). Skip creating the policy, as this will only cause errors
 // if TT are enforced.
 console.warn(
 'TrustedTypes policy ' + policyName + ' could not be created.'
 );
 return null;
 }
};

function createDOMPurify(window = getGlobal()) {
 const DOMPurify = root => createDOMPurify(root);

 /**
 * Version label, exposed for easier checks
 * if DOMPurify is up to date or not
 */
 DOMPurify.version = VERSION;

 /**
 * Array of elements that DOMPurify removed during sanitation.
 * Empty if nothing was removed.
 */
 DOMPurify.removed = [];

 if (!window || !window.document || window.document.nodeType !== 9) {
 // Not running in a browser, provide a factory function
 // so that you can pass your own Window
 DOMPurify.isSupported = false;

 return DOMPurify;
 }

 const originalDocument = window.document;
 let useDOMParser = false;
 let removeTitle = false;

 let { document } = window;
 const {
 DocumentFragment,
 HTMLTemplateElement,
 Node,
 NodeFilter,
 NamedNodeMap = window.NamedNodeMap || window.MozNamedAttrMap,
 Text,
 Comment,
 DOMParser,
 trustedTypes,
 } = window;

 // As per issue #47, the web-components registry is inherited by a
 // new document created via createHTMLDocument. As per the spec
 // (http://w3c.github.io/webcomponents/spec/custom/#creating-and-passing-registries)
 // a new empty registry is used when creating a template contents owner
 // document, so we use that as our parent document to ensure nothing
 // is inherited.
 if (typeof HTMLTemplateElement === 'function') {
 const template = document.createElement('template');
 if (template.content && template.content.ownerDocument) {
 document = template.content.ownerDocument;
 }
 }

 const trustedTypesPolicy = _createTrustedTypesPolicy(
 trustedTypes,
 originalDocument
 );
 const emptyHTML = trustedTypesPolicy ? trustedTypesPolicy.createHTML('') : '';

 const {
 implementation,
 createNodeIterator,
 getElementsByTagName,
 createDocumentFragment,
 } = document;
 const { importNode } = originalDocument;

 let hooks = {};

 /**
 * Expose whether this browser supports running the full DOMPurify.
 */
 DOMPurify.isSupported =
 implementation &&
 typeof implementation.createHTMLDocument !== 'undefined' &&
 document.documentMode !== 9;

 const {
 MUSTACHE_EXPR,
 ERB_EXPR,
 DATA_ATTR,
 ARIA_ATTR,
 IS_SCRIPT_OR_DATA,
 ATTR_WHITESPACE,
 } = EXPRESSIONS;

 let { IS_ALLOWED_URI } = EXPRESSIONS;

 /**
 * We consider the elements and attributes below to be safe. Ideally
 * don't add any new ones but feel free to remove unwanted ones.
 */

 /* allowed element names */
 let ALLOWED_TAGS = null;
 const DEFAULT_ALLOWED_TAGS = addToSet({}, [
 ...TAGS.html,
 ...TAGS.svg,
 ...TAGS.svgFilters,
 ...TAGS.mathMl,
 ...TAGS.text,
 ]);

 /* Allowed attribute names */
 let ALLOWED_ATTR = null;
 const DEFAULT_ALLOWED_ATTR = addToSet({}, [
 ...ATTRS.html,
 ...ATTRS.svg,
 ...ATTRS.mathMl,
 ...ATTRS.xml,
 ]);

 /* Explicitly forbidden tags (overrides ALLOWED_TAGS/ADD_TAGS) */
 let FORBID_TAGS = null;

 /* Explicitly forbidden attributes (overrides ALLOWED_ATTR/ADD_ATTR) */
 let FORBID_ATTR = null;

 /* Decide if ARIA attributes are okay */
 let ALLOW_ARIA_ATTR = true;

 /* Decide if custom data attributes are okay */
 let ALLOW_DATA_ATTR = true;

 /* Decide if unknown protocols are okay */
 let ALLOW_UNKNOWN_PROTOCOLS = false;

 /* Output should be safe for jQuery's $() factory? */
 let SAFE_FOR_JQUERY = false;

 /* Output should be safe for common template engines.
 * This means, DOMPurify removes data attributes, mustaches and ERB
 */
 let SAFE_FOR_TEMPLATES = false;

 /* Decide if document with <html>... should be returned */
 let WHOLE_DOCUMENT = false;

 /* Track whether config is already set on this instance of DOMPurify. */
 let SET_CONFIG = false;

 /* Decide if all elements (e.g. style, script) must be children of
 * document.body. By default, browsers might move them to document.head */
 let FORCE_BODY = false;

 /* Decide if a DOM `HTMLBodyElement` should be returned, instead of a html
 * string (or a TrustedHTML object if Trusted Types are supported).
 * If `WHOLE_DOCUMENT` is enabled a `HTMLHtmlElement` will be returned instead
 */
 let RETURN_DOM = false;

 /* Decide if a DOM `DocumentFragment` should be returned, instead of a html
 * string (or a TrustedHTML object if Trusted Types are supported) */
 let RETURN_DOM_FRAGMENT = false;

 /* If `RETURN_DOM` or `RETURN_DOM_FRAGMENT` is enabled, decide if the returned DOM
 * `Node` is imported into the current `Document`. If this flag is not enabled the
 * `Node` will belong (its ownerDocument) to a fresh `HTMLDocument`, created by
 * DOMPurify. */
 let RETURN_DOM_IMPORT = false;

 /* Try to return a Trusted Type object instead of a string, retrun a string in
 * case Trusted Types are not supported */
 let RETURN_TRUSTED_TYPE = false;

 /* Output should be free from DOM clobbering attacks? */
 let SANITIZE_DOM = true;

 /* Keep element content when removing element? */
 let KEEP_CONTENT = true;

 /* If a `Node` is passed to sanitize(), then performs sanitization in-place instead
 * of importing it into a new Document and returning a sanitized copy */
 let IN_PLACE = false;

 /* Allow usage of profiles like html, svg and mathMl */
 let USE_PROFILES = {};

 /* Tags to ignore content of when KEEP_CONTENT is true */
 const FORBID_CONTENTS = addToSet({}, [
 'annotation-xml',
 'audio',
 'colgroup',
 'desc',
 'foreignobject',
 'head',
 'iframe',
 'math',
 'mi',
 'mn',
 'mo',
 'ms',
 'mtext',
 'noembed',
 'noframes',
 'plaintext',
 'script',
 'style',
 'svg',
 'template',
 'thead',
 'title',
 'video',
 'xmp',
 ]);

 /* Tags that are safe for data: URIs */
 const DATA_URI_TAGS = addToSet({}, [
 'audio',
 'video',
 'img',
 'source',
 'image',
 ]);

 /* Attributes safe for values like "javascript:" */
 let URI_SAFE_ATTRIBUTES = null;
 const DEFAULT_URI_SAFE_ATTRIBUTES = addToSet({}, [
 'alt',
 'class',
 'for',
 'id',
 'label',
 'name',
 'pattern',
 'placeholder',
 'summary',
 'title',
 'value',
 'style',
 'xmlns',
 ]);

 /* Keep a reference to config to pass to hooks */
 let CONFIG = null;

 /* Ideally, do not touch anything below this line */
 /* ______________________________________________ */

 const formElement = document.createElement('form');

 /**
 * _parseConfig
 *
 * @param {Object} cfg optional config literal
 */
 // eslint-disable-next-line complexity
 const _parseConfig = function(cfg) {
 if (CONFIG && CONFIG === cfg) {
 return;
 }

 /* Shield configuration object from tampering */
 if (!cfg || typeof cfg !== 'object') {
 cfg = {};
 }

 /* Set configuration parameters */
 ALLOWED_TAGS =
 'ALLOWED_TAGS' in cfg
 ? addToSet({}, cfg.ALLOWED_TAGS)
 : DEFAULT_ALLOWED_TAGS;
 ALLOWED_ATTR =
 'ALLOWED_ATTR' in cfg
 ? addToSet({}, cfg.ALLOWED_ATTR)
 : DEFAULT_ALLOWED_ATTR;
 URI_SAFE_ATTRIBUTES =
 'ADD_URI_SAFE_ATTR' in cfg
 ? addToSet(clone(DEFAULT_URI_SAFE_ATTRIBUTES), cfg.ADD_URI_SAFE_ATTR)
 : DEFAULT_URI_SAFE_ATTRIBUTES;
 FORBID_TAGS = 'FORBID_TAGS' in cfg ? addToSet({}, cfg.FORBID_TAGS) : {};
 FORBID_ATTR = 'FORBID_ATTR' in cfg ? addToSet({}, cfg.FORBID_ATTR) : {};
 USE_PROFILES = 'USE_PROFILES' in cfg ? cfg.USE_PROFILES : false;
 ALLOW_ARIA_ATTR = cfg.ALLOW_ARIA_ATTR !== false; // Default true
 ALLOW_DATA_ATTR = cfg.ALLOW_DATA_ATTR !== false; // Default true
 ALLOW_UNKNOWN_PROTOCOLS = cfg.ALLOW_UNKNOWN_PROTOCOLS || false; // Default false
 SAFE_FOR_JQUERY = cfg.SAFE_FOR_JQUERY || false; // Default false
 SAFE_FOR_TEMPLATES = cfg.SAFE_FOR_TEMPLATES || false; // Default false
 WHOLE_DOCUMENT = cfg.WHOLE_DOCUMENT || false; // Default false
 RETURN_DOM = cfg.RETURN_DOM || false; // Default false
 RETURN_DOM_FRAGMENT = cfg.RETURN_DOM_FRAGMENT || false; // Default false
 RETURN_DOM_IMPORT = cfg.RETURN_DOM_IMPORT || false; // Default false
 RETURN_TRUSTED_TYPE = cfg.RETURN_TRUSTED_TYPE || false; // Default false
 FORCE_BODY = cfg.FORCE_BODY || false; // Default false
 SANITIZE_DOM = cfg.SANITIZE_DOM !== false; // Default true
 KEEP_CONTENT = cfg.KEEP_CONTENT !== false; // Default true
 IN_PLACE = cfg.IN_PLACE || false; // Default false
 IS_ALLOWED_URI = cfg.ALLOWED_URI_REGEXP || IS_ALLOWED_URI;
 if (SAFE_FOR_TEMPLATES) {
 ALLOW_DATA_ATTR = false;
 }

 if (RETURN_DOM_FRAGMENT) {
 RETURN_DOM = true;
 }

 /* Parse profile info */
 if (USE_PROFILES) {
 ALLOWED_TAGS = addToSet({}, [...TAGS.text]);
 ALLOWED_ATTR = [];
 if (USE_PROFILES.html === true) {
 addToSet(ALLOWED_TAGS, TAGS.html);
 addToSet(ALLOWED_ATTR, ATTRS.html);
 }

 if (USE_PROFILES.svg === true) {
 addToSet(ALLOWED_TAGS, TAGS.svg);
 addToSet(ALLOWED_ATTR, ATTRS.svg);
 addToSet(ALLOWED_ATTR, ATTRS.xml);
 }

 if (USE_PROFILES.svgFilters === true) {
 addToSet(ALLOWED_TAGS, TAGS.svgFilters);
 addToSet(ALLOWED_ATTR, ATTRS.svg);
 addToSet(ALLOWED_ATTR, ATTRS.xml);
 }

 if (USE_PROFILES.mathMl === true) {
 addToSet(ALLOWED_TAGS, TAGS.mathMl);
 addToSet(ALLOWED_ATTR, ATTRS.mathMl);
 addToSet(ALLOWED_ATTR, ATTRS.xml);
 }
 }

 /* Merge configuration parameters */
 if (cfg.ADD_TAGS) {
 if (ALLOWED_TAGS === DEFAULT_ALLOWED_TAGS) {
 ALLOWED_TAGS = clone(ALLOWED_TAGS);
 }

 addToSet(ALLOWED_TAGS, cfg.ADD_TAGS);
 }

 if (cfg.ADD_ATTR) {
 if (ALLOWED_ATTR === DEFAULT_ALLOWED_ATTR) {
 ALLOWED_ATTR = clone(ALLOWED_ATTR);
 }

 addToSet(ALLOWED_ATTR, cfg.ADD_ATTR);
 }

 if (cfg.ADD_URI_SAFE_ATTR) {
 addToSet(URI_SAFE_ATTRIBUTES, cfg.ADD_URI_SAFE_ATTR);
 }

 /* Add #text in case KEEP_CONTENT is set to true */
 if (KEEP_CONTENT) {
 ALLOWED_TAGS['#text'] = true;
 }

 /* Add html, head and body to ALLOWED_TAGS in case WHOLE_DOCUMENT is true */
 if (WHOLE_DOCUMENT) {
 addToSet(ALLOWED_TAGS, ['html', 'head', 'body']);
 }

 /* Add tbody to ALLOWED_TAGS in case tables are permitted, see #286, #365 */
 if (ALLOWED_TAGS.table) {
 addToSet(ALLOWED_TAGS, ['tbody']);
 delete FORBID_TAGS.tbody;
 }

 // Prevent further manipulation of configuration.
 // Not available in IE8, Safari 5, etc.
 if (freeze) {
 freeze(cfg);
 }

 CONFIG = cfg;
 };

 /**
 * _forceRemove
 *
 * @param {Node} node a DOM node
 */
 const _forceRemove = function(node) {
 arrayPush(DOMPurify.removed, { element: node });
 try {
 node.parentNode.removeChild(node);
 } catch (error) {
 node.outerHTML = emptyHTML;
 }
 };

 /**
 * _removeAttribute
 *
 * @param {String} name an Attribute name
 * @param {Node} node a DOM node
 */
 const _removeAttribute = function(name, node) {
 try {
 arrayPush(DOMPurify.removed, {
 attribute: node.getAttributeNode(name),
 from: node,
 });
 } catch (error) {
 arrayPush(DOMPurify.removed, {
 attribute: null,
 from: node,
 });
 }

 node.removeAttribute(name);
 };

 /**
 * _initDocument
 *
 * @param {String} dirty a string of dirty markup
 * @return {Document} a DOM, filled with the dirty markup
 */
 const _initDocument = function(dirty) {
 /* Create a HTML document */
 let doc;
 let leadingWhitespace;

 if (FORCE_BODY) {
 dirty = '<remove></remove>' + dirty;
 } else {
 /* If FORCE_BODY isn't used, leading whitespace needs to be preserved manually */
 const matches = stringMatch(dirty, /^[\s]+/);
 leadingWhitespace = matches && matches[0];
 }

 const dirtyPayload = trustedTypesPolicy
 ? trustedTypesPolicy.createHTML(dirty)
 : dirty;
 /* Use DOMParser to workaround Firefox bug (see comment below) */
 if (useDOMParser) {
 try {
 doc = new DOMParser().parseFromString(dirtyPayload, 'text/html');
 } catch (error) {}
 }

 /* Remove title to fix a mXSS bug in older MS Edge */
 if (removeTitle) {
 addToSet(FORBID_TAGS, ['title']);
 }

 /* Otherwise use createHTMLDocument, because DOMParser is unsafe in
 Safari (see comment below) */
 if (!doc || !doc.documentElement) {
 doc = implementation.createHTMLDocument('');
 const { body } = doc;
 body.parentNode.removeChild(body.parentNode.firstElementChild);
 body.outerHTML = dirtyPayload;
 }

 if (dirty && leadingWhitespace) {
 doc.body.insertBefore(
 document.createTextNode(leadingWhitespace),
 doc.body.childNodes[0] || null
 );
 }

 /* Work on whole document or just its body */
 return getElementsByTagName.call(doc, WHOLE_DOCUMENT ? 'html' : 'body')[0];
 };

 // Firefox uses a different parser for innerHTML rather than
 // DOMParser (see https://bugzilla.mozilla.org/show_bug.cgi?id=1205631)
 // which means that you *must* use DOMParser, otherwise the output may
 // not be safe if used in a document.write context later.
 //
 // So we feature detect the Firefox bug and use the DOMParser if necessary.
 //
 // Chrome 77 and other versions ship an mXSS bug that caused a bypass to
 // happen. We now check for the mXSS trigger and react accordingly.
 if (DOMPurify.isSupported) {
 (function() {
 try {
 const doc = _initDocument(
 '<svg><p><textarea><img src="</textarea><img src=x abc=1//">'
 );
 if (doc.querySelector('svg img')) {
 useDOMParser = true;
 }
 } catch (error) {}
 })();

 (function() {
 try {
 const doc = _initDocument('<x/><title>&lt;/title&gt;&lt;img&gt;');
 if (regExpTest(/<\/title/, doc.querySelector('title').innerHTML)) {
 removeTitle = true;
 }
 } catch (error) {}
 })();
 }

 /**
 * _createIterator
 *
 * @param {Document} root document/fragment to create iterator for
 * @return {Iterator} iterator instance
 */
 const _createIterator = function(root) {
 return createNodeIterator.call(
 root.ownerDocument || root,
 root,
 NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_COMMENT | NodeFilter.SHOW_TEXT,
 () => {
 return NodeFilter.FILTER_ACCEPT;
 },
 false
 );
 };

 /**
 * _isClobbered
 *
 * @param {Node} elm element to check for clobbering attacks
 * @return {Boolean} true if clobbered, false if safe
 */
 const _isClobbered = function(elm) {
 if (elm instanceof Text || elm instanceof Comment) {
 return false;
 }

 if (
 typeof elm.nodeName !== 'string' ||
 typeof elm.textContent !== 'string' ||
 typeof elm.removeChild !== 'function' ||
 !(elm.attributes instanceof NamedNodeMap) ||
 typeof elm.removeAttribute !== 'function' ||
 typeof elm.setAttribute !== 'function' ||
 typeof elm.namespaceURI !== 'string'
 ) {
 return true;
 }

 return false;
 };

 /**
 * _isNode
 *
 * @param {Node} obj object to check whether it's a DOM node
 * @return {Boolean} true is object is a DOM node
 */
 const _isNode = function(obj) {
 return typeof Node === 'object'
 ? obj instanceof Node
 : obj &&
 typeof obj === 'object' &&
 typeof obj.nodeType === 'number' &&
 typeof obj.nodeName === 'string';
 };

 /**
 * _executeHook
 * Execute user configurable hooks
 *
 * @param {String} entryPoint Name of the hook's entry point
 * @param {Node} currentNode node to work on with the hook
 * @param {Object} data additional hook parameters
 */
 const _executeHook = function(entryPoint, currentNode, data) {
 if (!hooks[entryPoint]) {
 return;
 }

 arrayForEach(hooks[entryPoint], hook => {
 hook.call(DOMPurify, currentNode, data, CONFIG);
 });
 };

 /**
 * _sanitizeElements
 *
 * @protect nodeName
 * @protect textContent
 * @protect removeChild
 *
 * @param {Node} currentNode to check for permission to exist
 * @return {Boolean} true if node was killed, false if left alive
 */
 // eslint-disable-next-line complexity
 const _sanitizeElements = function(currentNode) {
 let content;

 /* Execute a hook if present */
 _executeHook('beforeSanitizeElements', currentNode, null);

 /* Check if element is clobbered or can clobber */
 if (_isClobbered(currentNode)) {
 _forceRemove(currentNode);
 return true;
 }

 /* Now let's check the element's type and name */
 const tagName = stringToLowerCase(currentNode.nodeName);

 /* Execute a hook if present */
 _executeHook('uponSanitizeElement', currentNode, {
 tagName,
 allowedTags: ALLOWED_TAGS,
 });

 /* Take care of an mXSS pattern using p, br inside svg, math */
 if (
 (tagName === 'svg' || tagName === 'math') &&
 currentNode.querySelectorAll('p, br').length !== 0
 ) {
 _forceRemove(currentNode);
 return true;
 }

 /* Remove element if anything forbids its presence */
 if (!ALLOWED_TAGS[tagName] || FORBID_TAGS[tagName]) {
 /* Keep content except for black-listed elements */
 if (
 KEEP_CONTENT &&
 !FORBID_CONTENTS[tagName] &&
 typeof currentNode.insertAdjacentHTML === 'function'
 ) {
 try {
 const htmlToInsert = currentNode.innerHTML;
 currentNode.insertAdjacentHTML(
 'AfterEnd',
 trustedTypesPolicy
 ? trustedTypesPolicy.createHTML(htmlToInsert)
 : htmlToInsert
 );
 } catch (error) {}
 }

 _forceRemove(currentNode);
 return true;
 }

 /* Remove in case a noscript/noembed XSS is suspected */
 if (
 tagName === 'noscript' &&
 regExpTest(/<\/noscript/i, currentNode.innerHTML)
 ) {
 _forceRemove(currentNode);
 return true;
 }

 if (
 tagName === 'noembed' &&
 regExpTest(/<\/noembed/i, currentNode.innerHTML)
 ) {
 _forceRemove(currentNode);
 return true;
 }

 /* Convert markup to cover jQuery behavior */
 if (
 SAFE_FOR_JQUERY &&
 !currentNode.firstElementChild &&
 (!currentNode.content || !currentNode.content.firstElementChild) &&
 regExpTest(/</g, currentNode.textContent)
 ) {
 arrayPush(DOMPurify.removed, { element: currentNode.cloneNode() });
 if (currentNode.innerHTML) {
 currentNode.innerHTML = stringReplace(
 currentNode.innerHTML,
 /</g,
 '&lt;'
 );
 } else {
 currentNode.innerHTML = stringReplace(
 currentNode.textContent,
 /</g,
 '&lt;'
 );
 }
 }

 /* Sanitize element content to be template-safe */
 if (SAFE_FOR_TEMPLATES && currentNode.nodeType === 3) {
 /* Get the element's text content */
 content = currentNode.textContent;
 content = stringReplace(content, MUSTACHE_EXPR, ' ');
 content = stringReplace(content, ERB_EXPR, ' ');
 if (currentNode.textContent !== content) {
 arrayPush(DOMPurify.removed, { element: currentNode.cloneNode() });
 currentNode.textContent = content;
 }
 }

 /* Execute a hook if present */
 _executeHook('afterSanitizeElements', currentNode, null);

 return false;
 };

 /**
 * _isValidAttribute
 *
 * @param {string} lcTag Lowercase tag name of containing element.
 * @param {string} lcName Lowercase attribute name.
 * @param {string} value Attribute value.
 * @return {Boolean} Returns true if `value` is valid, otherwise false.
 */
 // eslint-disable-next-line complexity
 const _isValidAttribute = function(lcTag, lcName, value) {
 /* Make sure attribute cannot clobber */
 if (
 SANITIZE_DOM &&
 (lcName === 'id' || lcName === 'name') &&
 (value in document || value in formElement)
 ) {
 return false;
 }

 /* Allow valid data-* attributes: At least one character after "-"
 (https://html.spec.whatwg.org/multipage/dom.html#embedding-custom-non-visible-data-with-the-data-*-attributes)
 XML-compatible (https://html.spec.whatwg.org/multipage/infrastructure.html#xml-compatible and http://www.w3.org/TR/xml/#d0e804)
 We don't need to check the value; it's always URI safe. */
 if (ALLOW_DATA_ATTR && regExpTest(DATA_ATTR, lcName)) {
 // This attribute is safe
 } else if (ALLOW_ARIA_ATTR && regExpTest(ARIA_ATTR, lcName)) {
 // This attribute is safe
 /* Otherwise, check the name is permitted */
 } else if (!ALLOWED_ATTR[lcName] || FORBID_ATTR[lcName]) {
 return false;

 /* Check value is safe. First, is attr inert? If so, is safe */
 } else if (URI_SAFE_ATTRIBUTES[lcName]) {
 // This attribute is safe
 /* Check no script, data or unknown possibly unsafe URI
 unless we know URI values are safe for that attribute */
 } else if (
 regExpTest(IS_ALLOWED_URI, stringReplace(value, ATTR_WHITESPACE, ''))
 ) {
 // This attribute is safe
 /* Keep image data URIs alive if src/xlink:href is allowed */
 /* Further prevent gadget XSS for dynamically built script tags */
 } else if (
 (lcName === 'src' || lcName === 'xlink:href' || lcName === 'href') &&
 lcTag !== 'script' &&
 stringIndexOf(value, 'data:') === 0 &&
 DATA_URI_TAGS[lcTag]
 ) {
 // This attribute is safe
 /* Allow unknown protocols: This provides support for links that
 are handled by protocol handlers which may be unknown ahead of
 time, e.g. fb:, spotify: */
 } else if (
 ALLOW_UNKNOWN_PROTOCOLS &&
 !regExpTest(IS_SCRIPT_OR_DATA, stringReplace(value, ATTR_WHITESPACE, ''))
 ) {
 // This attribute is safe
 /* Check for binary attributes */
 // eslint-disable-next-line no-negated-condition
 } else if (!value) {
 // Binary attributes are safe at this point
 /* Anything else, presume unsafe, do not add it back */
 } else {
 return false;
 }

 return true;
 };

 /**
 * _sanitizeAttributes
 *
 * @protect attributes
 * @protect nodeName
 * @protect removeAttribute
 * @protect setAttribute
 *
 * @param {Node} currentNode to sanitize
 */
 // eslint-disable-next-line complexity
 const _sanitizeAttributes = function(currentNode) {
 let attr;
 let value;
 let lcName;
 let idAttr;
 let l;
 /* Execute a hook if present */
 _executeHook('beforeSanitizeAttributes', currentNode, null);

 let { attributes } = currentNode;

 /* Check if we have attributes; if not we might have a text node */
 if (!attributes) {
 return;
 }

 const hookEvent = {
 attrName: '',
 attrValue: '',
 keepAttr: true,
 allowedAttributes: ALLOWED_ATTR,
 };
 l = attributes.length;

 /* Go backwards over all attributes; safely remove bad ones */
 while (l--) {
 attr = attributes[l];
 const { name, namespaceURI } = attr;
 value = stringTrim(attr.value);
 lcName = stringToLowerCase(name);

 /* Execute a hook if present */
 hookEvent.attrName = lcName;
 hookEvent.attrValue = value;
 hookEvent.keepAttr = true;
 hookEvent.forceKeepAttr = undefined; // Allows developers to see this is a property they can set
 _executeHook('uponSanitizeAttribute', currentNode, hookEvent);
 value = hookEvent.attrValue;
 /* Did the hooks approve of the attribute? */
 if (hookEvent.forceKeepAttr) {
 continue;
 }

 /* Remove attribute */
 // Safari (iOS + Mac), last tested v8.0.5, crashes if you try to
 // remove a "name" attribute from an <img> tag that has an "id"
 // attribute at the time.
 if (
 lcName === 'name' &&
 currentNode.nodeName === 'IMG' &&
 attributes.id
 ) {
 idAttr = attributes.id;
 attributes = arraySlice(attributes, []);
 _removeAttribute('id', currentNode);
 _removeAttribute(name, currentNode);
 if (arrayIndexOf(attributes, idAttr) > l) {
 currentNode.setAttribute('id', idAttr.value);
 }
 } else if (
 // This works around a bug in Safari, where input[type=file]
 // cannot be dynamically set after type has been removed
 currentNode.nodeName === 'INPUT' &&
 lcName === 'type' &&
 value === 'file' &&
 hookEvent.keepAttr &&
 (ALLOWED_ATTR[lcName] || !FORBID_ATTR[lcName])
 ) {
 continue;
 } else {
 // This avoids a crash in Safari v9.0 with double-ids.
 // The trick is to first set the id to be empty and then to
 // remove the attribute
 if (name === 'id') {
 currentNode.setAttribute(name, '');
 }

 _removeAttribute(name, currentNode);
 }

 /* Did the hooks approve of the attribute? */
 if (!hookEvent.keepAttr) {
 continue;
 }

 /* Work around a security issue in jQuery 3.0 */
 if (SAFE_FOR_JQUERY && regExpTest(/\/>/i, value)) {
 _removeAttribute(name, currentNode);
 continue;
 }

 /* Take care of an mXSS pattern using namespace switches */
 if (
 regExpTest(/svg|math/i, currentNode.namespaceURI) &&
 regExpTest(
 regExpCreate(
 '</(' + arrayJoin(objectKeys(FORBID_CONTENTS), '|') + ')',
 'i'
 ),
 value
 )
 ) {
 _removeAttribute(name, currentNode);
 continue;
 }

 /* Sanitize attribute content to be template-safe */
 if (SAFE_FOR_TEMPLATES) {
 value = stringReplace(value, MUSTACHE_EXPR, ' ');
 value = stringReplace(value, ERB_EXPR, ' ');
 }

 /* Is `value` valid for this attribute? */
 const lcTag = currentNode.nodeName.toLowerCase();
 if (!_isValidAttribute(lcTag, lcName, value)) {
 continue;
 }

 /* Handle invalid data-* attribute set by try-catching it */
 try {
 if (namespaceURI) {
 currentNode.setAttributeNS(namespaceURI, name, value);
 } else {
 /* Fallback to setAttribute() for browser-unrecognized namespaces e.g. "x-schema". */
 currentNode.setAttribute(name, value);
 }

 arrayPop(DOMPurify.removed);
 } catch (error) {}
 }

 /* Execute a hook if present */
 _executeHook('afterSanitizeAttributes', currentNode, null);
 };

 /**
 * _sanitizeShadowDOM
 *
 * @param {DocumentFragment} fragment to iterate over recursively
 */
 const _sanitizeShadowDOM = function(fragment) {
 let shadowNode;
 const shadowIterator = _createIterator(fragment);

 /* Execute a hook if present */
 _executeHook('beforeSanitizeShadowDOM', fragment, null);

 while ((shadowNode = shadowIterator.nextNode())) {
 /* Execute a hook if present */
 _executeHook('uponSanitizeShadowNode', shadowNode, null);

 /* Sanitize tags and elements */
 if (_sanitizeElements(shadowNode)) {
 continue;
 }

 /* Deep shadow DOM detected */
 if (shadowNode.content instanceof DocumentFragment) {
 _sanitizeShadowDOM(shadowNode.content);
 }

 /* Check attributes, sanitize if necessary */
 _sanitizeAttributes(shadowNode);
 }

 /* Execute a hook if present */
 _executeHook('afterSanitizeShadowDOM', fragment, null);
 };

 /**
 * Sanitize
 * Public method providing core sanitation functionality
 *
 * @param {String|Node} dirty string or DOM node
 * @param {Object} configuration object
 */
 // eslint-disable-next-line complexity
 DOMPurify.sanitize = function(dirty, cfg) {
 let body;
 let importedNode;
 let currentNode;
 let oldNode;
 let returnNode;
 /* Make sure we have a string to sanitize.
 DO NOT return early, as this will return the wrong type if
 the user has requested a DOM object rather than a string */
 if (!dirty) {
 dirty = '<!-->';
 }

 /* Stringify, in case dirty is an object */
 if (typeof dirty !== 'string' && !_isNode(dirty)) {
 // eslint-disable-next-line no-negated-condition
 if (typeof dirty.toString !== 'function') {
 throw typeErrorCreate('toString is not a function');
 } else {
 dirty = dirty.toString();
 if (typeof dirty !== 'string') {
 throw typeErrorCreate('dirty is not a string, aborting');
 }
 }
 }

 /* Check we can run. Otherwise fall back or ignore */
 if (!DOMPurify.isSupported) {
 if (
 typeof window.toStaticHTML === 'object' ||
 typeof window.toStaticHTML === 'function'
 ) {
 if (typeof dirty === 'string') {
 return window.toStaticHTML(dirty);
 }

 if (_isNode(dirty)) {
 return window.toStaticHTML(dirty.outerHTML);
 }
 }

 return dirty;
 }

 /* Assign config vars */
 if (!SET_CONFIG) {
 _parseConfig(cfg);
 }

 /* Clean up removed elements */
 DOMPurify.removed = [];

 /* Check if dirty is correctly typed for IN_PLACE */
 if (typeof dirty === 'string') {
 IN_PLACE = false;
 }

 if (IN_PLACE) {
 /* No special handling necessary for in-place sanitization */
 } else if (dirty instanceof Node) {
 /* If dirty is a DOM element, append to an empty document to avoid
 elements being stripped by the parser */
 body = _initDocument('<!-->');
 importedNode = body.ownerDocument.importNode(dirty, true);
 if (importedNode.nodeType === 1 && importedNode.nodeName === 'BODY') {
 /* Node is already a body, use as is */
 body = importedNode;
 } else if (importedNode.nodeName === 'HTML') {
 body = importedNode;
 } else {
 // eslint-disable-next-line unicorn/prefer-node-append
 body.appendChild(importedNode);
 }
 } else {
 /* Exit directly if we have nothing to do */
 if (
 !RETURN_DOM &&
 !SAFE_FOR_TEMPLATES &&
 !WHOLE_DOCUMENT &&
 RETURN_TRUSTED_TYPE &&
 dirty.indexOf('<') === -1
 ) {
 return trustedTypesPolicy
 ? trustedTypesPolicy.createHTML(dirty)
 : dirty;
 }

 /* Initialize the document to work on */
 body = _initDocument(dirty);

 /* Check we have a DOM node from the data */
 if (!body) {
 return RETURN_DOM ? null : emptyHTML;
 }
 }

 /* Remove first element node (ours) if FORCE_BODY is set */
 if (body && FORCE_BODY) {
 _forceRemove(body.firstChild);
 }

 /* Get node iterator */
 const nodeIterator = _createIterator(IN_PLACE ? dirty : body);

 /* Now start iterating over the created document */
 while ((currentNode = nodeIterator.nextNode())) {
 /* Fix IE's strange behavior with manipulated textNodes #89 */
 if (currentNode.nodeType === 3 && currentNode === oldNode) {
 continue;
 }

 /* Sanitize tags and elements */
 if (_sanitizeElements(currentNode)) {
 continue;
 }

 /* Shadow DOM detected, sanitize it */
 if (currentNode.content instanceof DocumentFragment) {
 _sanitizeShadowDOM(currentNode.content);
 }

 /* Check attributes, sanitize if necessary */
 _sanitizeAttributes(currentNode);

 oldNode = currentNode;
 }

 oldNode = null;

 /* If we sanitized `dirty` in-place, return it. */
 if (IN_PLACE) {
 return dirty;
 }

 /* Return sanitized string or DOM */
 if (RETURN_DOM) {
 if (RETURN_DOM_FRAGMENT) {
 returnNode = createDocumentFragment.call(body.ownerDocument);

 while (body.firstChild) {
 // eslint-disable-next-line unicorn/prefer-node-append
 returnNode.appendChild(body.firstChild);
 }
 } else {
 returnNode = body;
 }

 if (RETURN_DOM_IMPORT) {
 /* AdoptNode() is not used because internal state is not reset
 (e.g. the past names map of a HTMLFormElement), this is safe
 in theory but we would rather not risk another attack vector.
 The state that is cloned by importNode() is explicitly defined
 by the specs. */
 returnNode = importNode.call(originalDocument, returnNode, true);
 }

 return returnNode;
 }

 let serializedHTML = WHOLE_DOCUMENT ? body.outerHTML : body.innerHTML;

 /* Sanitize final string template-safe */
 if (SAFE_FOR_TEMPLATES) {
 serializedHTML = stringReplace(serializedHTML, MUSTACHE_EXPR, ' ');
 serializedHTML = stringReplace(serializedHTML, ERB_EXPR, ' ');
 }

 return trustedTypesPolicy && RETURN_TRUSTED_TYPE
 ? trustedTypesPolicy.createHTML(serializedHTML)
 : serializedHTML;
 };

 /**
 * Public method to set the configuration once
 * setConfig
 *
 * @param {Object} cfg configuration object
 */
 DOMPurify.setConfig = function(cfg) {
 _parseConfig(cfg);
 SET_CONFIG = true;
 };

 /**
 * Public method to remove the configuration
 * clearConfig
 *
 */
 DOMPurify.clearConfig = function() {
 CONFIG = null;
 SET_CONFIG = false;
 };

 /**
 * Public method to check if an attribute value is valid.
 * Uses last set config, if any. Otherwise, uses config defaults.
 * isValidAttribute
 *
 * @param {string} tag Tag name of containing element.
 * @param {string} attr Attribute name.
 * @param {string} value Attribute value.
 * @return {Boolean} Returns true if `value` is valid. Otherwise, returns false.
 */
 DOMPurify.isValidAttribute = function(tag, attr, value) {
 /* Initialize shared config vars if necessary. */
 if (!CONFIG) {
 _parseConfig({});
 }

 const lcTag = stringToLowerCase(tag);
 const lcName = stringToLowerCase(attr);
 return _isValidAttribute(lcTag, lcName, value);
 };

 /**
 * AddHook
 * Public method to add DOMPurify hooks
 *
 * @param {String} entryPoint entry point for the hook to add
 * @param {Function} hookFunction function to execute
 */
 DOMPurify.addHook = function(entryPoint, hookFunction) {
 if (typeof hookFunction !== 'function') {
 return;
 }

 hooks[entryPoint] = hooks[entryPoint] || [];
 arrayPush(hooks[entryPoint], hookFunction);
 };

 /**
 * RemoveHook
 * Public method to remove a DOMPurify hook at a given entryPoint
 * (pops it from the stack of hooks if more are present)
 *
 * @param {String} entryPoint entry point for the hook to remove
 */
 DOMPurify.removeHook = function(entryPoint) {
 if (hooks[entryPoint]) {
 arrayPop(hooks[entryPoint]);
 }
 };

 /**
 * RemoveHooks
 * Public method to remove all DOMPurify hooks at a given entryPoint
 *
 * @param {String} entryPoint entry point for the hooks to remove
 */
 DOMPurify.removeHooks = function(entryPoint) {
 if (hooks[entryPoint]) {
 hooks[entryPoint] = [];
 }
 };

 /**
 * RemoveAllHooks
 * Public method to remove all DOMPurify hooks
 *
 */
 DOMPurify.removeAllHooks = function() {
 hooks = {};
 };

 return DOMPurify;
}

export default createDOMPurify();
