import { freeze } from './utils';

export const html = freeze([
 'accept',
 'action',
 'align',
 'alt',
 'autocomplete',
 'background',
 'bgcolor',
 'border',
 'cellpadding',
 'cellspacing',
 'checked',
 'cite',
 'class',
 'clear',
 'color',
 'cols',
 'colspan',
 'controls',
 'coords',
 'crossorigin',
 'datetime',
 'default',
 'dir',
 'disabled',
 'download',
 'enctype',
 'face',
 'for',
 'headers',
 'height',
 'hidden',
 'high',
 'href',
 'hreflang',
 'id',
 'integrity',
 'ismap',
 'label',
 'lang',
 'list',
 'loop',
 'low',
 'max',
 'maxlength',
 'media',
 'method',
 'min',
 'minlength',
 'multiple',
 'name',
 'noshade',
 'novalidate',
 'nowrap',
 'open',
 'optimum',
 'pattern',
 'placeholder',
 'poster',
 'preload',
 'pubdate',
 'radiogroup',
 'readonly',
 'rel',
 'required',
 'rev',
 'reversed',
 'role',
 'rows',
 'rowspan',
 'spellcheck',
 'scope',
 'selected',
 'shape',
 'size',
 'sizes',
 'span',
 'srclang',
 'start',
 'src',
 'srcset',
 'step',
 'style',
 'summary',
 'tabindex',
 'title',
 'type',
 'usemap',
 'valign',
 'value',
 'width',
 'xmlns',
]);

export const svg = freeze([
 'accent-height',
 'accumulate',
 'additive',
 'alignment-baseline',
 'ascent',
 'attributename',
 'attributetype',
 'azimuth',
 'basefrequency',
 'baseline-shift',
 'begin',
 'bias',
 'by',
 'class',
 'clip',
 'clip-path',
 'clip-rule',
 'color',
 'color-interpolation',
 'color-interpolation-filters',
 'color-profile',
 'color-rendering',
 'cx',
 'cy',
 'd',
 'dx',
 'dy',
 'diffuseconstant',
 'direction',
 'display',
 'divisor',
 'dur',
 'edgemode',
 'elevation',
 'end',
 'fill',
 'fill-opacity',
 'fill-rule',
 'filter',
 'filterunits',
 'flood-color',
 'flood-opacity',
 'font-family',
 'font-size',
 'font-size-adjust',
 'font-stretch',
 'font-style',
 'font-variant',
 'font-weight',
 'fx',
 'fy',
 'g1',
 'g2',
 'glyph-name',
 'glyphref',
 'gradientunits',
 'gradienttransform',
 'height',
 'href',
 'id',
 'image-rendering',
 'in',
 'in2',
 'k',
 'k1',
 'k2',
 'k3',
 'k4',
 'kerning',
 'keypoints',
 'keysplines',
 'keytimes',
 'lang',
 'lengthadjust',
 'letter-spacing',
 'kernelmatrix',
 'kernelunitlength',
 'lighting-color',
 'local',
 'marker-end',
 'marker-mid',
 'marker-start',
 'markerheight',
 'markerunits',
 'markerwidth',
 'maskcontentunits',
 'maskunits',
 'max',
 'mask',
 'media',
 'method',
 'mode',
 'min',
 'name',
 'numoctaves',
 'offset',
 'operator',
 'opacity',
 'order',
 'orient',
 'orientation',
 'origin',
 'overflow',
 'paint-order',
 'path',
 'pathlength',
 'patterncontentunits',
 'patterntransform',
 'patternunits',
 'points',
 'preservealpha',
 'preserveaspectratio',
 'primitiveunits',
 'r',
 'rx',
 'ry',
 'radius',
 'refx',
 'refy',
 'repeatcount',
 'repeatdur',
 'restart',
 'result',
 'rotate',
 'scale',
 'seed',
 'shape-rendering',
 'specularconstant',
 'specularexponent',
 'spreadmethod',
 'stddeviation',
 'stitchtiles',
 'stop-color',
 'stop-opacity',
 'stroke-dasharray',
 'stroke-dashoffset',
 'stroke-linecap',
 'stroke-linejoin',
 'stroke-miterlimit',
 'stroke-opacity',
 'stroke',
 'stroke-width',
 'style',
 'surfacescale',
 'tabindex',
 'targetx',
 'targety',
 'transform',
 'text-anchor',
 'text-decoration',
 'text-rendering',
 'textlength',
 'type',
 'u1',
 'u2',
 'unicode',
 'values',
 'viewbox',
 'visibility',
 'version',
 'vert-adv-y',
 'vert-origin-x',
 'vert-origin-y',
 'width',
 'word-spacing',
 'wrap',
 'writing-mode',
 'xchannelselector',
 'ychannelselector',
 'x',
 'x1',
 'x2',
 'xmlns',
 'y',
 'y1',
 'y2',
 'z',
 'zoomandpan',
]);

export const mathMl = freeze([
 'accent',
 'accentunder',
 'align',
 'bevelled',
 'close',
 'columnsalign',
 'columnlines',
 'columnspan',
 'denomalign',
 'depth',
 'dir',
 'display',
 'displaystyle',
 'encoding',
 'fence',
 'frame',
 'height',
 'href',
 'id',
 'largeop',
 'length',
 'linethickness',
 'lspace',
 'lquote',
 'mathbackground',
 'mathcolor',
 'mathsize',
 'mathvariant',
 'maxsize',
 'minsize',
 'movablelimits',
 'notation',
 'numalign',
 'open',
 'rowalign',
 'rowlines',
 'rowspacing',
 'rowspan',
 'rspace',
 'rquote',
 'scriptlevel',
 'scriptminsize',
 'scriptsizemultiplier',
 'selection',
 'separator',
 'separators',
 'stretchy',
 'subscriptshift',
 'supscriptshift',
 'symmetric',
 'voffset',
 'width',
 'xmlns',
]);

export const xml = freeze([
 'xlink:href',
 'xml:id',
 'xlink:title',
 'xml:space',
 'xmlns:xlink',
]);
